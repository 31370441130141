interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  name: string;
  value?: string;
  title?: string;
  type?: React.HTMLInputTypeAttribute;
  required?: boolean;
  min?: string;
  className?: any;
}

const Input = (props: Props) => {
  const { placeholder, onChange, name, value, title, type, required, className } = props;

  return (
    <div className="w-full flex flex-col gap-2">
      <label htmlFor={name} className="text-textColor text-sm">
        {title}
      </label>
      <input
        {...props}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className={`w-full text-sm appearance-none py-2 px-5 text-black leading-5 outline-none ${
          className?.rounded ?? "rounded-lg"
        }  border-[1px] border-borderStroke bg-white focus:border-focusStroke ${className}}`}
        name={name}
        id={name}
        type={type}
        required={required}
      />
    </div>
  );
};

export default Input;
